import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../utils/api';

const RemoveUser = () => {
  const [email, setEmail] = useState(''); // Track email input
  const [code, setCode] = useState(''); // Track verification code input
  const [message, setMessage] = useState(null); // Track success/error messages
  const [loading, setLoading] = useState(false); // Track loading state
  const [showCodeInput, setShowCodeInput] = useState(false); // Show verification code input
  const [emailDisabled, setEmailDisabled] = useState(false); // Disable email input

  const navigate = useNavigate(); // React Router's navigate function for redirection

  const handleRequestDelete = async () => {
    setMessage(null); // Clear previous messages
    setLoading(true); // Start loading indicator

    try {
      // Call the backend endpoint to request deletion
      const response = await Api.post('/users/requestDelete', { email });

      if (response.status === 200) {
        setMessage(response.message); // Display "Verification code sent to your email."
        setShowCodeInput(true); // Show the verification code input
        setEmailDisabled(true); // Disable the email input
      }
    } catch (error) {
      console.error('Error requesting account deletion:', error);
      setMessage(
        error.response?.message || 'Failed to send verification code.'
      ); // Show error message
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  const handleConfirmDelete = async () => {
    setMessage(null); // Clear previous messages
    setLoading(true); // Start loading indicator

    try {
      // Call the backend endpoint to delete with the code
      const response = await Api.delete('/users/confirmAccountDeletion', {
        email,
        code,
      });

      // Check if the response is successful
      if (response.status === 200) {
        setMessage(response.message || 'Account successfully deleted.'); // Display success message
        setTimeout(() => {
          navigate('/'); // Redirect to main page after success
        }, 1500); // Optional: Small delay for user to see the success message
      } else {
        // Handle unexpected status codes
        setMessage('Failed to delete account. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      setMessage(
        error.response?.message || 'Failed to delete account. Please try again.'
      ); // Show error message
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '400px', margin: 'auto' }}>
      <h1>Remove User from Database</h1>

      {/* Email Input */}
      <div style={{ marginBottom: '16px' }}>
        <label
          htmlFor='email'
          style={{ display: 'block', marginBottom: '8px' }}
        >
          Enter your email to request deletion:
        </label>
        <input
          id='email'
          type='email'
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder='Enter your email'
          disabled={emailDisabled} // Disable input if emailDisabled is true
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            backgroundColor: emailDisabled ? '#f2f2f2' : '#fff',
          }}
        />
      </div>

      {/* Remove Button */}
      {!showCodeInput && (
        <button
          onClick={handleRequestDelete}
          disabled={loading}
          style={{
            padding: '10px 20px',
            backgroundColor: loading ? '#ddd' : '#f44336',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: loading ? 'not-allowed' : 'pointer',
          }}
        >
          {loading ? 'Processing...' : 'Remove'}
        </button>
      )}

      {/* Verification Code Input */}
      {showCodeInput && (
        <>
          <div style={{ marginTop: '16px', marginBottom: '16px' }}>
            <label
              htmlFor='code'
              style={{ display: 'block', marginBottom: '8px' }}
            >
              Enter the verification code sent to your email:
            </label>
            <input
              id='code'
              type='text'
              value={code}
              onChange={e => setCode(e.target.value)}
              placeholder='Enter verification code'
              style={{
                width: '100%',
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            />
          </div>

          {/* Confirm Delete Button */}
          <button
            onClick={handleConfirmDelete}
            disabled={loading}
            style={{
              padding: '10px 20px',
              backgroundColor: loading ? '#ddd' : '#f44336',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: loading ? 'not-allowed' : 'pointer',
            }}
          >
            {loading ? 'Processing...' : 'Confirm Deletion'}
          </button>
        </>
      )}

      {/* Message Display */}
      {message && (
        <p
          style={{
            marginTop: '16px',
            color: message.includes('Failed') ? 'red' : 'green',
          }}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default RemoveUser;
