import React from 'react';
import * as S from './styles'; // Import all styled components with `S`

export const UserList = ({ users, selectedUser, setSelectedUser }) => {
  return (
    <S.UserListContainer>
      {users?.map(user => {
        return (
          <S.UserCard
            key={user._id}
            $active={user._id === selectedUser?._id}
            onClick={() => setSelectedUser(user)}
          >
            {/* Uncomment if avatars are used */}
            {/* <S.Avatar src={user.avatar} alt={`${user.firstName} avatar`} /> */}

            <div style={{textAlign: 'left'}}>
              <S.UserName>
                {user.firstName} {user.lastName}
              </S.UserName>
              <S.UserEmail>{user.email}</S.UserEmail>
              <S.UserId>{user._id}</S.UserId>
            </div>
          </S.UserCard>
        );
      })}
    </S.UserListContainer>
  );
};
