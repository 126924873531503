import styled from 'styled-components';

export const UserListContainer = styled.div`
  height: 900px; // Set a fixed height for the scrollable area
  width: 100%;
  overflow-y: auto; // Enable vertical scrolling
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

export const UserCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 12px;
  background: ${({ $active }) => ($active ? '#d3e3ff' : 'white')};
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #e0e0e0;

  &:hover {
    background-color: ${({ $active }) => ($active ? '#aacbff' : '#f5f5f5')};
  }

  @media (max-width: 730px) {
    max-width: 120px;
  }
`;

export const Avatar = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 12px;
`;

export const UserName = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #333;
`;

export const UserEmail = styled.div`
  font-size: 12px;
  color: #555;
`;

export const UserId = styled.div`
  font-size: 12px;
  color: #888;
  margin-top: 2px;
  word-break: break-word; /* Handle long IDs gracefully */
`;
