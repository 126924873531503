import React, { useState, useEffect } from 'react';
import { Title } from '../Typography';
import * as S from './styles';

const buildBreadCrumbs = (breadCrumb) => {
  if (typeof breadCrumb === 'string') return breadCrumb;

  return breadCrumb.map((word, i) => {
    if (i === breadCrumb.length - 1) {
      return <S.BoldBreadCrumb key={word}>{word}</S.BoldBreadCrumb>;
    }

    return `${word} / `;
  });
};

export const Layout = ({
  headerChildren,
  sideBarChildren,
  children,
  title,
  navbar,
  staticHeight = false,
  hideNavbar,
  hideHeader,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 730);
    };

    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <S.Layout>
      {headerChildren}

      {!hideHeader && (
        <S.Header>
          <S.TitleWrapper>
            <Title level={2} $transform="capitalize">
              {title && buildBreadCrumbs(title)}
            </Title>
          </S.TitleWrapper>
          {navbar}
        </S.Header>
      )}

      <S.Layout
        $hasPadding
        hasSider={!!sideBarChildren}
        $staticHeight={staticHeight}
        $hideNavbar={hideNavbar}
        style={isSmallScreen ? { flexDirection: 'column' } : {}}
      >
        {!!sideBarChildren && (
          <S.Sider style={isSmallScreen ? { height: 120, display: 'flex' } : {}}>
            {sideBarChildren}
          </S.Sider>
        )}
        <S.Content style={isSmallScreen ? { width: 'auto' } : {}}>
          {children}
        </S.Content>
      </S.Layout>
    </S.Layout>
  );
};
