import React, { useState } from 'react';
import { Card, Button, Modal } from 'antd';
import { CollapsibleSection } from './CollapsibleSection';

export const WorkCard = ({ work }) => {
  const [isUserModalVisible, setUserModalVisible] = useState(false);
  const [isBoatModalVisible, setBoatModalVisible] = useState(false);
  const [isActionSectionVisible, setActionSectionVisible] = useState(false);

  const showUserModal = () => {
    setUserModalVisible(true);
  };

  const showBoatModal = () => {
    setBoatModalVisible(true);
  };

  const hideUserModal = () => {
    setUserModalVisible(false);
  };

  const hideBoatModal = () => {
    setBoatModalVisible(false);
  };

  const toggleActionSection = () => {
    setActionSectionVisible(!isActionSectionVisible);
  };

  return (
    <Card key={work._id} title={work.title} style={{ marginBottom: '16px' }}>
      <p>
        <strong>Region:</strong> {work.location.region}
      </p>
      <p>
        <strong>Country:</strong> {work.location.country}
      </p>
      <p>
        <strong>Status:</strong> {work.status}
      </p>
      <p>
        <strong>Service Type:</strong> {work.serviceType}
      </p>
      <p>
        <strong>Due Date:</strong> {new Date(work.dueDate).toLocaleString()}
      </p>

      {work.media.length > 0 && (
        <div>
          <strong>Media:</strong>
          <div
            style={{
              display: 'flex',
              gap: '8px',
              marginTop: '8px',
              flexWrap: 'wrap',
            }}
          >
            {work.media.map((mediaItem, index) => (
              <img
                key={index}
                src={mediaItem.thumbnail}
                alt={`Work media ${index + 1}`}
                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
              />
            ))}
          </div>
        </div>
      )}

      <div style={{ marginTop: '16px' }}>
        <Button type="primary" onClick={showUserModal} style={{ marginRight: '8px' }}>
          Show User Info
        </Button>
        <Button type="default" onClick={showBoatModal} style={{ marginRight: '8px' }}>
          Show Boat Info
        </Button>
        <Button type="primary" onClick={toggleActionSection}>
          Another Action
        </Button>
      </div>

      {isActionSectionVisible && (
        <CollapsibleSection
          location={work.location}
          user={work.owner}
          boat={work.owner.boat}
          work={work}
        />
      )}

      <Modal
        title="User Information"
        open={isUserModalVisible}
        onCancel={hideUserModal}
        footer={[
          <Button key="close" onClick={hideUserModal}>
            Close
          </Button>,
        ]}
      >
        <p>
          <strong>Name:</strong> {work.owner.firstName} {work.owner.lastName}
        </p>
        <p>
          <strong>Email:</strong> {work.owner.email}
        </p>
      </Modal>

      <Modal
        title="Boat Information"
        open={isBoatModalVisible}
        onCancel={hideBoatModal}
        footer={[
          <Button key="close" onClick={hideBoatModal}>
            Close
          </Button>,
        ]}
      >
        <p>
          <strong>Boat Name:</strong> {work?.owner?.boat?.name}
        </p>
        <p>
          <strong>Type:</strong> {work?.owner?.boat?.type}
        </p>
        <p>
          <strong>Length:</strong> {work?.owner?.boat?.length} meters
        </p>
        <p>
          <strong>Width:</strong> {work?.owner?.boat?.width} meters
        </p>
      </Modal>
    </Card>
  );
};
