/** @format */

import React, { useState, useEffect } from 'react';
import { Table, Input, Pagination, Switch } from 'antd';
import axios from 'axios';
import styled from 'styled-components';
import { useAuthContext } from '../../../utils';
import { Thumbnail } from '../../common';
import Api from '../../../utils/api';

const StyledTableWrapper = styled.div`
  margin: 0 8px;
  .ant-table-cell {
    padding: 8px 4px !important;
  }
`;

export const AgencyPortal = () => {
  const { token } = useAuthContext();
  const [verifiedAgencies, setVerifiedAgencies] = useState([]);
  const [unverifiedAgencies, setUnverifiedAgencies] = useState([]);
  const [verifiedCount, setVerifiedCount] = useState(0);
  const [unverifiedCount, setUnverifiedCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [verifiedPage, setVerifiedPage] = useState(1);
  const [unverifiedPage, setUnverifiedPage] = useState(1);
  const [limit, setLimit] = useState(50); // Default limit to 50
  const [copiedPhone, setCopiedPhone] = useState(null); // Track copied phone number with unique ID

  const updateAgencyValue = async ({
    agencyId,
    field,
    value,
    oceanLabsRegistered = false,
  }) => {
    setLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/updateAgency`, {
        agencyId,
        field,
        value,
      });

      const currentData = oceanLabsRegistered
        ? verifiedAgencies
        : unverifiedAgencies;

      const updatedData = currentData.map(item =>
        item._id === agencyId ? { ...item, [field]: value } : item,
      );

      if (oceanLabsRegistered) {
        setVerifiedAgencies(updatedData);
      } else {
        setUnverifiedAgencies(updatedData);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAgencies = async () => {
    setLoading(true);

    try {
      // Fetch verified agencies
      const verifiedResponse = await Api.get('/admin/getAgencies', {
        page: verifiedPage,
        limit,
        search,
      });

      setVerifiedAgencies(verifiedResponse.verifiedAgencies);
      setVerifiedCount(verifiedResponse.verifiedCount);

      // Fetch unverified agencies
      const unverifiedResponse = await Api.get('/admin/getAgencies', {
        page: unverifiedPage,
        limit,
        search,
      });

      setUnverifiedAgencies(unverifiedResponse.unverifiedAgencies);
      setUnverifiedCount(unverifiedResponse.unverifiedCount);
    } catch (error) {
      console.error('Error fetching agencies:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
      fetchAgencies();
    }
  }, [token, verifiedPage, unverifiedPage, limit, search]);

  const handleCopy = (phoneNumber, id) => {
    if (phoneNumber) {
      navigator.clipboard.writeText(phoneNumber);
      setCopiedPhone({ id, phoneNumber });
      setTimeout(() => setCopiedPhone(null), 1000); // Reset copied phone after 1 second
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
      width: 60,
      render: id => (
        <div
          style={{
            maxWidth: '60px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            color: '#1890ff',
          }}
          onClick={() => {
            navigator.clipboard.writeText(id);
            setCopiedPhone({ id, phoneNumber: null }); // Mark ID copied
            setTimeout(() => setCopiedPhone(null), 1000);
          }}
          title={id}
        >
          {id}
        </div>
      ),
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: avatar => <Thumbnail src={avatar} alt='Avatar' />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 120,
      render: (_, data) => {
        const phoneNumber =
          data.verified || !data.placeDetails
            ? data.phone
            : data.placeDetails?.international_phone_number;

        const isCopied =
          copiedPhone &&
          copiedPhone.id === data._id &&
          copiedPhone.phoneNumber === phoneNumber;

        return (
          <div
            style={{
              maxWidth: '120px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
              color: isCopied ? '#52c41a' : '#1890ff',
            }}
            onClick={() => handleCopy(phoneNumber, data._id)}
            title={phoneNumber || 'N/A'}
          >
            {phoneNumber || 'N/A'}
          </div>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: address => <span>{address?.full}</span>,
    },
    {
      title: 'Region',
      dataIndex: 'address',
      key: 'region',
      render: address => <span>{address?.region}</span>,
    },
    {
      title: 'Country',
      dataIndex: 'address',
      key: 'country',
      render: address => <span>{address?.country}</span>,
    },
    {
      title: 'Listed',
      dataIndex: 'isListed',
      key: 'isListed',
      render: (_, data) => (
        <Switch
          disabled={loading}
          checked={data.isListed}
          onChange={val =>
            updateAgencyValue({
              agencyId: data._id,
              field: 'isListed',
              value: val,
              oceanLabsRegistered: verifiedAgencies.includes(data),
            })
          }
        />
      ),
    },
  ];

  return (
    <StyledTableWrapper>
      <Input
        placeholder='Search agencies'
        value={search}
        onChange={e => setSearch(e.target.value.toLowerCase())}
      />

      <div>
        <h3>Verified Agencies</h3>
        <Table
          rowKey='_id'
          dataSource={verifiedAgencies}
          columns={columns}
          pagination={false}
          loading={loading}
        />
        <Pagination
          current={verifiedPage}
          total={verifiedCount}
          pageSize={limit}
          pageSizeOptions={[10, 20, 100]}
          showSizeChanger
          onShowSizeChange={(_, newLimit) => setLimit(newLimit)}
          onChange={page => setVerifiedPage(page)}
        />
      </div>

      <div>
        <h3>Unverified Agencies</h3>
        <Table
          rowKey='_id'
          dataSource={unverifiedAgencies}
          columns={columns}
          pagination={false}
          loading={loading}
        />
        <Pagination
          current={unverifiedPage}
          total={unverifiedCount}
          pageSize={limit}
          pageSizeOptions={[10, 20, 100]}
          showSizeChanger
          onShowSizeChange={(_, newLimit) => setLimit(newLimit)}
          onChange={page => setUnverifiedPage(page)}
        />
      </div>
    </StyledTableWrapper>
  );
};

export default AgencyPortal;
