// src/LandingPage.js
import React from 'react';
import './index.css';
import logo from './assets/images/oceanlabs-logo.png';
import { Link } from 'react-router-dom';

const LandingPage = () => (
  <div className='landing-page'>
    <div className='content'>
      <div className='app-info'>
        <div className='app-logo'>
          <img src={logo} alt='Ocean Labs Logo' />
        </div>
        <h1>Ocean Labs</h1>
        <p>Your agency in the cloud.</p>
        <div className='download-buttons'>
          <a
            className='button ios'
            href='https://apps.apple.com/tr/app/ocean-labs/id6738892255'
            aria-disabled='true'
            aria-label='Download from Google Play Store'
            target='_blank'
            rel='noopener noreferrer'
          />
          <a
            className='button playstore'
            href='https://play.google.com/store/apps/details?id=com.oceanlabs.oceanlabs'
            target='_blank'
            rel='noopener noreferrer'
            aria-label='Download from Google Play Store'
          />
        </div>

        <p className='legal'>
          <Link target='_blank' to='/privacy-policy' style={{ color: 'white' }}>
            Privacy Policy
          </Link>
        </p>
        <p className='legal'>
          <Link
            target='_blank'
            to='/terms-and-conditions'
            style={{ color: 'white' }}
          >
            Terms and Conditions
          </Link>
        </p>
        <p className='legal'>
          <Link target='_blank' to='/data-agreement' style={{ color: 'white' }}>
            Data Processing Agreement
          </Link>
        </p>
        <p className='website'>www.oceanlabs.app</p>
      </div>
      <div className='phone-display'>
        <img
          src={require('./assets/images/app-screenshot.png')}
          alt='App Screenshot'
        />
      </div>
    </div>
  </div>
);

export default LandingPage;
